import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "de-bed-and-breakfast-voor-een-bezoek-aan-nancy-en-metz"
    }}>{`De `}<strong parentName="h1">{`bed and breakfast`}</strong>{` voor een bezoek aan `}<strong parentName="h1">{`Nancy en Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVCzok8mP//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMSEf/aAAgBAQABBQJ2T1bLQojno0ucrP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGhf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGtf//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4SkTSjX6f/xAAbEAEAAwEAAwAAAAAAAAAAAAABABFRMSFBkf/aAAgBAQABPyG+Goclx9nSIl+GWxR0bLKLPlwfE//aAAwDAQACAAMAAAAQy+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qo2P/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EKVNH//EABwQAAICAgMAAAAAAAAAAAAAAAERACExQXGBof/aAAgBAQABPxBk7FCmri14U7qA5AJsElS78LCFj2JN9APsRgdtwKf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/f994e/image.webp 163w", "/static/28e4fc40eb91a87992ead592da6b2869/bcd70/image.webp 325w", "/static/28e4fc40eb91a87992ead592da6b2869/5d8d7/image.webp 650w", "/static/28e4fc40eb91a87992ead592da6b2869/6c7d1/image.webp 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/367e5/image.jpg 163w", "/static/28e4fc40eb91a87992ead592da6b2869/ab07c/image.jpg 325w", "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg 650w", "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg",
              "alt": "authentiek frans bed and breakfast voor een bezoek aan frankrijk",
              "title": "authentiek frans bed and breakfast voor een bezoek aan frankrijk",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`De gastenkamers`}</strong></p>
    <h2 {...{
      "id": "een-romantische-nacht-in-een-kasteel-in-lotharingen-"
    }}>{`Een romantische nacht in een kasteel in `}<strong parentName="h2">{`Lotharingen`}</strong>{` ?`}</h2>
    <h3 {...{
      "id": "rustig-en-kalmte-verzekerd--een-paar-minuten-van-nancy"
    }}>{`Rustig en kalmte verzekerd … Een paar minuten van `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`We zijn al 25 jaar actief als bed and breakfast. Het is een groot genoegen om elk jaar meer dan 5.000 reizigers bij ons te mogen verwelkomen.`}</p>
    <p>{`We verwelkomen u zodat u Lorraine kunt ontdekken, zorgen voor een moment van romantiek, u zult ontsnappen aan uw dagelijks leven, of andere, het is met enthousiasme dat we u toestaan om uw pleziertjes te realiseren.`}</p>
    <p>{`Het is belangrijk voor het Château de Morey om u comfort en rust te beloven. Onze kamers zijn allemaal perfect geïsoleerd met zichtbare stenen muren, ze zorgen allemaal voor een uitzonderlijk comfort met kwaliteitsvol beddengoed. Al onze kamers hebben ook de bijzonderheid zeer ruim te zijn.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "voor-uw-intieme-nachten-in-lotharingen"
    }}>{`Voor uw intieme nachten in `}<strong parentName="h2">{`Lotharingen`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQLqGZ5pD0bo2j//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwG4Y//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhEjFh/9oACAEBAAY/AppekvOS2bP/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFh4f/aAAgBAQABPyHCr6vBrb6OGWLJHBFzZhqfM//aAAwDAQACAAMAAAAQZw//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QEmW3/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHhABAAICAgMBAAAAAAAAAAAAAQARIUExUWFxobH/2gAIAQEAAT8QSiAtifqNRCjPPbfcuC78m4SwBgOT3mMgMg0AcfYwDun/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/f994e/tourelle2.webp 163w", "/static/e5f290bd73f3916fcf58131988aa1728/bcd70/tourelle2.webp 325w", "/static/e5f290bd73f3916fcf58131988aa1728/5d8d7/tourelle2.webp 650w", "/static/e5f290bd73f3916fcf58131988aa1728/c4443/tourelle2.webp 975w", "/static/e5f290bd73f3916fcf58131988aa1728/5cc32/tourelle2.webp 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/1dd8b/tourelle2.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/367e5/tourelle2.jpg 163w", "/static/e5f290bd73f3916fcf58131988aa1728/ab07c/tourelle2.jpg 325w", "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg 650w", "/static/e5f290bd73f3916fcf58131988aa1728/cdb69/tourelle2.jpg 975w", "/static/e5f290bd73f3916fcf58131988aa1728/2616f/tourelle2.jpg 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg",
              "alt": "bed and breakfast in de buurt van Nancy",
              "title": "bed and breakfast in de buurt van Nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast in de buurt van Nancy`}</strong></p>
    <h3 {...{
      "id": "deze-bruidskamer-in-het-hart-van-de-meurthe-et-moezelstreek-belooft-u-een-romantisch-verblijf"
    }}>{`Deze bruidskamer in het hart van de `}<strong parentName="h3">{`Meurthe-et-Moezelstreek`}</strong>{` belooft u een romantisch verblijf.`}</h3>
    <h4 {...{
      "id": "de-tourelle-voor-de-tortelduifjes-in-bed-and-breakfast"
    }}>{`De Tourelle voor de tortelduifjes in bed and breakfast`}</h4>
    <p>{`Deze kamer bevindt zich in een van de torens van het kasteel. De charme van de zichtbare stenen voert u mee in een middeleeuwse sfeer. Breng uw nacht door in een hemelbed van 180m, comfort en rust zijn verzekerd.`}</p>
    <p>{`Geniet van het uitzicht op ons grote bos en op het ruiterpad van het Kasteel.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "de-favoriet-van-de-chatelaine-lorraine"
    }}>{`De favoriet van de Chatelaine Lorraine`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABn0teJWSB3//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFAgtJC8POkTix07//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAECAQE/AbGP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhUf/aAAgBAQAGPwLPBwtlss//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyHaBY4ljDdF+TZhGFHosfVt1H7T/9oADAMBAAIAAwAAABDD/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EEPCH//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAgEBPxCGqf/EAB0QAQEAAgEFAAAAAAAAAAAAAAERACFRMUGBsdH/2gAIAQEAAT8QODNdIUQ2YBFUiMJ7w8b1bLnU+h5T7kYBpV7WTN2jOM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/f994e/chambres-dhotes-airbnb-nancy.webp 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/bcd70/chambres-dhotes-airbnb-nancy.webp 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5d8d7/chambres-dhotes-airbnb-nancy.webp 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/c4443/chambres-dhotes-airbnb-nancy.webp 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5cc32/chambres-dhotes-airbnb-nancy.webp 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/1dd8b/chambres-dhotes-airbnb-nancy.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/367e5/chambres-dhotes-airbnb-nancy.jpg 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ab07c/chambres-dhotes-airbnb-nancy.jpg 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/cdb69/chambres-dhotes-airbnb-nancy.jpg 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/2616f/chambres-dhotes-airbnb-nancy.jpg 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg",
              "alt": "bed and breakfast in de buurt van metz",
              "title": "bed and breakfast in de buurt van metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast in de buurt van metz`}</strong></p>
    <h3 {...{
      "id": "een-nacht-in-de-buurt-van-nancy-in-een-prinsessenslaapkamer"
    }}>{`Een nacht in de buurt van Nancy in een prinsessenslaapkamer`}</h3>
    <p>{`Een ruime kamer van 64m2 en een hemelbed zorgen ervoor dat u een nacht van koning en koningin beleeft. Geniet van een grote Italiaanse douche en een zithoek om te ontspannen. Een prachtig uitzicht op de Natagne vallei met zijn Lotharingse kleurenschetsen.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "een-familiemoment-in-de-family-suite-voor-een-verblijf-in-nancy-"
    }}>{`Een familiemoment in de Family Suite voor een verblijf in `}<strong parentName="h2">{`Nancy`}</strong>{` ?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAV3JFEvcREP/xAAZEAADAQEBAAAAAAAAAAAAAAAAARECAxL/2gAIAQEAAQUCsOe7qpiRxfke4//EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8BnFP/xAAVEQEBAAAAAAAAAAAAAAAAAAACEP/aAAgBAgEBPwEz/8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBAhAR/9oACAEBAAY/Ajg7bKP/xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhMUFRcf/aAAgBAQABPyF4Hu6Epwd6liIzvXTlH3vBL9ymBLv/2gAMAwEAAgADAAAAEEM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJNP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEA/9oACAECAQE/EBaajv/EABoQAQEBAAMBAAAAAAAAAAAAAAERACExQWH/2gAIAQEAAT8QQ2leJkNH1IAajpAHA1N8g84ihhG9rxqYqgTrf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/f994e/suite.webp 163w", "/static/c3536ba51be167d9b8df18ef93761b14/bcd70/suite.webp 325w", "/static/c3536ba51be167d9b8df18ef93761b14/5d8d7/suite.webp 650w", "/static/c3536ba51be167d9b8df18ef93761b14/c4443/suite.webp 975w", "/static/c3536ba51be167d9b8df18ef93761b14/5cc32/suite.webp 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/1dd8b/suite.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/367e5/suite.jpg 163w", "/static/c3536ba51be167d9b8df18ef93761b14/ab07c/suite.jpg 325w", "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg 650w", "/static/c3536ba51be167d9b8df18ef93761b14/cdb69/suite.jpg 975w", "/static/c3536ba51be167d9b8df18ef93761b14/2616f/suite.jpg 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg",
              "alt": "bed and breakfast near nancy",
              "title": "bed and breakfast near nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bnb near nancy`}</strong></p>
    <h3 {...{
      "id": "geniet-van-uw-tijd-met-uw-gezin-in-lotharingen-bij-nancy-en-metz"
    }}>{`Geniet van uw tijd met uw gezin in `}<strong parentName="h3">{`Lotharingen`}</strong>{` bij `}<strong parentName="h3">{`Nancy`}</strong>{` en `}<strong parentName="h3">{`Metz`}</strong>{`.`}</h3>
    <p>{`Het kasteel verwelkomt u als koppel en als familie, door een majestueuze kamer te voorzien voor uw koppel, vergezeld van een annex kamer voor uw kinderen.`}</p>
    <p>{`U geniet van een ruime badkamer en een adembenemend uitzicht op de romaanse kerk uit de XIIe eeuw en de grote Lotharingse vallei.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "zoete-lente-zoete-lotharingen"
    }}>{`Zoete lente, Zoete Lotharingen`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdMTWs75kcT/xAAcEAABAwUAAAAAAAAAAAAAAAABAAISAwQRISP/2gAIAQEAAQUCbpSMcxVN5BuBxX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BrX//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIR/9oACAECAQE/Aawx/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEREiExQf/aAAgBAQAGPwIStGI8EajT/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUaH/2gAIAQEAAT8hdFEQPYtoCq7lFl9mtmCsFl5MvU//2gAMAwEAAgADAAAAEF8//8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxBnFzTZv//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFh/9oACAECAQE/EKGpFs//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhQVGR/9oACAEBAAE/EFouJGKoGp18y8gRecZlNMieHJ4g+oBPWV603//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/f994e/printaniere.webp 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/bcd70/printaniere.webp 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/5d8d7/printaniere.webp 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/c4443/printaniere.webp 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/5cc32/printaniere.webp 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/1dd8b/printaniere.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/367e5/printaniere.jpg 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/ab07c/printaniere.jpg 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/cdb69/printaniere.jpg 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/2616f/printaniere.jpg 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg",
              "alt": "bed and breakfast",
              "title": "bed and breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast`}</strong></p>
    <h3 {...{
      "id": "een-verblijf-in-een-gastenkamer-met-de-warme-kleur-van-de-mooie-dagen"
    }}>{`Een verblijf in een gastenkamer met de warme kleur van de mooie dagen`}</h3>
    <p>{`Ontspan in deze serre met zijn decoratie die het een Toscaanse uitstraling geeft. U zult genieten van het panoramische uitzicht op het park van het kasteel en de vallei van `}<strong parentName="p">{`Meurthe et Moselle`}</strong>{`. Een grote badkamer met een ruime zithoek zal u toelaten om te ontspannen tijdens uw reis tussen `}<strong parentName="p">{`Nancy`}</strong>{` en `}<strong parentName="p">{`Metz`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "vliegen-in-de-colvert"
    }}>{`Vliegen in de Colvert`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB79YoOsnrf//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQATEv/aAAgBAQABBQKws4U+rurDdC7wjf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQIxIf/aAAgBAQAGPwKEhc8L0VSPNNH/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQVExYf/aAAgBAQABPyG2NcGPJgTYPxBjjR3uNVF8nkM4T//aAAwDAQACAAMAAAAQ2M//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhgf/aAAgBAwEBPxB25S5f/8QAGBEAAgMAAAAAAAAAAAAAAAAAADEBEWH/2gAIAQIBAT8QhMrT/8QAGxABAQADAAMAAAAAAAAAAAAAAREAITFRYZH/2gAIAQEAAT8QdS8Qu9swkjyafcNJ3HjHhuKFWPdyLwoTEPFx0s+Yf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/f994e/colvert.webp 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/bcd70/colvert.webp 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5d8d7/colvert.webp 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/c4443/colvert.webp 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5cc32/colvert.webp 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/1dd8b/colvert.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/367e5/colvert.jpg 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ab07c/colvert.jpg 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/cdb69/colvert.jpg 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/2616f/colvert.jpg 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg",
              "alt": "bed and breakfast",
              "title": "bed and breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast`}</strong></p>
    <h3 {...{
      "id": "het-symbool-van-lotharingen-brengt-u-naar-een-authentiek-moment"
    }}>{`Het symbool van `}<strong parentName="h3">{`Lotharingen`}</strong>{` brengt u naar een authentiek moment.`}</h3>
    <p>{`Trakteer uzelf op een overnachting in een kamer van 43m2 met een dubbel `}<strong parentName="p">{`bed`}</strong>{` van 200x200. U vindt er een bad om te ontspannen. In deze `}<strong parentName="p">{`kamer`}</strong>{` kunt u ook genieten van het uitzicht op de vallei en het park van het kasteel. Geniet van de charme van deze kamer die de authenticiteit van `}<strong parentName="p">{`Lotharingen`}</strong>{` weerspiegelt.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>
    <h2 {...{
      "id": "lunch-in-alle-rust"
    }}>{`Lunch in alle rust`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABtykZ1Q54f//EABoQAQADAAMAAAAAAAAAAAAAAAIAASEDEjL/2gAIAQEAAQUCDNxvL2EdIvV8u//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQETJh4f/aAAgBAQAGPwJ6HR2cUf/EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8huh/DpLXfNaomFPVVt3E+l+56q7//2gAMAwEAAgADAAAAENTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EAWt/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAgEBPxBPLC//xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhQWExUXGhwf/aAAgBAQABPxA8oppxkEhEYa03n3EGGhsMWi+mpre2RcDMTXU399MNaHyTjP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/f994e/pdj.webp 163w", "/static/ba3ef274c3ef583c5369d5411d433412/bcd70/pdj.webp 325w", "/static/ba3ef274c3ef583c5369d5411d433412/5d8d7/pdj.webp 650w", "/static/ba3ef274c3ef583c5369d5411d433412/c4443/pdj.webp 975w", "/static/ba3ef274c3ef583c5369d5411d433412/5cc32/pdj.webp 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/1dd8b/pdj.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/367e5/pdj.jpg 163w", "/static/ba3ef274c3ef583c5369d5411d433412/ab07c/pdj.jpg 325w", "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg 650w", "/static/ba3ef274c3ef583c5369d5411d433412/cdb69/pdj.jpg 975w", "/static/ba3ef274c3ef583c5369d5411d433412/2616f/pdj.jpg 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg",
              "alt": "breakfast",
              "title": "breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`breakfast and relax`}</strong></p>
    <h3 {...{
      "id": "een-moment-van-intimiteit-in-de-vroege-ochtend-in-lotharingen-is-gegarandeerd"
    }}>{`Een moment van intimiteit in de vroege ochtend in `}<strong parentName="h3">{`Lotharingen`}</strong>{` is gegarandeerd.`}</h3>
    <p>{`Hier is de formule :`}</p>
    <ul>
      <li parentName="ul">{`Warme dranken ☕`}</li>
      <li parentName="ul">{`Weens gebak 🍞`}</li>
      <li parentName="ul">{`Biologisch sinaasappelsap 🍊`}</li>
      <li parentName="ul">{`Yoghurt en fruit 🍶🍐🍏`}</li>
      <li parentName="ul">{`U zou dit gratis ontbijt, dat is samengesteld uit lokale en Lotharingse producten, op prijs stellen.`}</li>
    </ul>
    <h2 {...{
      "id": "kleine-details-worden-vaak-gewaardeerd"
    }}>{`Kleine details worden vaak gewaardeerd`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "bed and breakfast tussen nancy en metz",
              "title": "bed and breakfast tussen nancy en metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast tussen nancy en metz`}</strong></p>
    <p>{`Het zal u niet ontbreken tijdens uw verblijf tussen Nancy en Metz.
We weten dat het belangrijk is, de uitrusting van onze kamers zijn er om uw reis te perfectioneren in Meurthe et Moselle, Lorraine of Nancy. U vindt in al onze kamers:`}</p>
    <ul>
      <li parentName="ul">{`Wifi access 🌐`}</li>
      <li parentName="ul">{`Haardroger 💇♀`}</li>
      <li parentName="ul">{`Douchegel & shampoo 🚿`}</li>
      <li parentName="ul">{`Handdoeken & Badjassen 💆♀`}</li>
      <li parentName="ul">{`Documentatie over Lotharingen 📜`}</li>
      <li parentName="ul">{`Zithoek 🛋`}</li>
      <li parentName="ul">{`Verwonder uw moment in de buurt van Nancy en Metz met meerdere activiteiten in onze vestiging, zoals SPA, massage, zwembad, paardrijden…`}</li>
    </ul>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Boek</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      